<template>
  <div class="content_body ReportCustomerArrearsDetail" style="padding:15px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:0 0 15px 0">
      <el-form :inline="true" size="small" :model="searchSaleTreatData" @submit.native.prevent>
        <el-form-item label="顾客姓名/编号/手机号">
          <el-input v-model="searchSaleTreatData.Name" clearable @keyup.enter.native="handleSaleTreatSearch"
            @clear="handleSaleTreatSearch" placeholder="请输入顾客姓名/编号/手机号"></el-input>
        </el-form-item>
        <el-form-item label="顾客等级">
          <el-select v-model="searchSaleTreatData.LevelID" clearable filterable placeholder="请选择顾客等级"
            :default-first-option="true" @change="handleSaleTreatSearch">
            <el-option v-for="(item,index) in customerLevel" :key="index" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="顾客来源">
          <el-cascader v-model="searchSaleTreatData.SourceID"  placeholder="请选择顾客来源" :options="customerSource"
            :props="{ checkStrictly: true ,children:'Child',value:'ID',label:'Name',emitPath:false}"
            :show-all-levels="false" clearable filterable @change="handleSaleTreatSearch"></el-cascader>
        </el-form-item>
        <el-form-item label="注册日期">
          <el-date-picker v-model="searchSaleTreatData.CreatedOn" unlink-panels type="daterange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleTreatSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="storeEntityList.length>1" label="所属门店">
          <el-select v-model="searchSaleTreatData.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleSaleTreatSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消费日期">
          <el-date-picker v-model="searchSaleTreatData.consumeDate" unlink-panels type="daterange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleTreatSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSaleTreatSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="SaleTreatAnalysisExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadSaleTreatExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getsaleTreatDetailListSummaries" :data="result.list"
      v-loading="saleTreatDetailLoading">
      <el-table-column prop="Name" label="顾客姓名/编号"></el-table-column>
      <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
      <el-table-column prop="LevelName" label="顾客等级"></el-table-column>
      <el-table-column prop="SourceName" label="顾客来源"></el-table-column>
      <el-table-column prop="Birthday" label="生日"></el-table-column>
      <el-table-column prop="CreatedOn" label="注册日期"></el-table-column>
      <el-table-column prop="EntityName" label="所属门店"></el-table-column>
      <el-table-column prop="EarlyWeight" label="初始体重"></el-table-column>
      <el-table-column prop="TotalSaleQuantity" align="right" label="累计购买次数"></el-table-column>
      <el-table-column prop="TotalSaleAmount" align="right" label="累计购买金额">
        <template slot-scope="scope">
          {{scope.row.TotalSaleAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="TotalSalePrice" align="right" label="购买均价/次">
        <template slot-scope="scope">
          {{scope.row.TotalSalePrice | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentSaleQuantity" align="right" label="本期购买次数"></el-table-column>
      <el-table-column prop="CurrentSaleAmount" align="right" label="本期购买金额">
        <template slot-scope="scope">
          {{scope.row.CurrentSaleAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentConsumeProjectCount" align="right" label="本期到店消耗项目次数"></el-table-column>
      <el-table-column prop="CurrentConsumeProjectQuantity" align="right" label="本期消耗项目数量"></el-table-column>
      <el-table-column prop="CurrentConsumeProjectAmount" align="right" label="本期消耗项目金额">
        <template slot-scope="scope">
          {{scope.row.CurrentConsumeProjectAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentConsumeProjectAmount" align="right" label="消耗项目均价/次">
        <template slot-scope="scope">
          {{scope.row.CurrentConsumeProjectAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="LastSaleBillDate" align="right" label="最近一次购买日期"></el-table-column>
      <el-table-column prop="UnSaleDay" align="right" label="未购买天数"></el-table-column>
      <el-table-column prop="LastConsumeBillDate" align="right" label="最近一次消耗项目日期"></el-table-column>
      <el-table-column prop="UnConsumeDay" align="right" label="未消耗项目天数"></el-table-column>
      <el-table-column prop="ToShopQuantity" align="right" label="本期到店次数"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="saleTreatPaginations.total > 0" @current-change="handleSaleTreatDetailPageChange"
        :current-page.sync="saleTreatPaginations.page" :page-size="saleTreatPaginations.page_size"
        :layout="saleTreatPaginations.layout" :total="saleTreatPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Customer/saleTreatAnalysis";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import EntityAPI from "@/api/Report/Common/entity";
import permission from "@/components/js/permission.js";
import dateTime from '@/components/js/date'
export default {
  name: "CustomerSaleTreatAnalysis",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      customerLevel: [], //顾客等级
      customerSource: [], //顾客来源
      storeEntityList: [], //权限下所属门店
      // 搜索条件
      searchSaleTreatData: {
        Name: null,
        LevelID: null,
        SourceID: null,
        EntityID: null,
        CreatedOn: [],
        consumeDate: [],
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      SaleTreatAnalysisExport: false, //导出权限
      downloadLoading: false,
      saleTreatDetailLoading: false,
      saleTreatPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  created(){
    this.searchSaleTreatData.CreatedOn=[dateTime.formatDate.format(new Date(),'YYYY-MM-DD'),dateTime.formatDate.format(new Date(),'YYYY-MM-DD')]
    this.searchSaleTreatData.consumeDate=[dateTime.formatDate.format(new Date(),'YYYY-MM-DD'),dateTime.formatDate.format(new Date(),'YYYY-MM-DD')]
  },
  mounted() {
    const that = this;
    //   导出权限
    that.SaleTreatAnalysisExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Customer-SaleTreatAnalysis-Export"
    );
    // 等级
    that.CustomerLevelData();
    // 来源
    that.CustomerSourceData();
    //获得当前用户下的权限门店
    that.getstoreEntityList();
    // 搜索
    that.handleSaleTreatSearch();
  },

  methods: {
    // 顾客等级
    CustomerLevelData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 搜索
    handleSaleTreatSearch() {
      console.log(1);
      var that = this;
      that.saleTreatPaginations.page = 1;
      that.getDynamicSaleAndDynamicConsume();
    },
    // 获取动销动耗列表
    getDynamicSaleAndDynamicConsume() {
      const that = this;
      const param = that.searchSaleTreatData;

      if (param.consumeDate!=null) {
        that.saleTreatDetailLoading = true;
        const params = {
          Name: param.Name,
          StartTime: param.consumeDate[0],
          EndTime: param.consumeDate[1],
          LevelID: param.LevelID,
          SourceID: param.SourceID,
          StartCreatedOn: param.CreatedOn?param.CreatedOn[0]:'',
          EndCreatedOn: param.CreatedOn?param.CreatedOn[1]:'',
          EntityID: param.EntityID,
          PageNum: that.saleTreatPaginations.page,
        };
        API.dynamicSaleAndDynamicConsume(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.result.totalForm =
                res.Data.customerDynamicSaleAndDynamicConsumeSumStatementForm;
              that.result.list = res.Data.detail.List;
              that.saleTreatPaginations.total = res.Data.detail.Total;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.saleTreatDetailLoading = false;
          });
      }
    },
    // 导出
    downloadSaleTreatExcel() {
      var that = this;
      const param = that.searchSaleTreatData;
      if (param.consumeDate.length) {
        let params = {
          Name: param.Name,
          StartTime: param.consumeDate[0],
          EndTime: param.consumeDate[1],
          StartCreatedOn: param.CreatedOn?param.CreatedOn[0]:'',
          EndCreatedOn: param.CreatedOn?param.CreatedOn[1]:'',
          LevelID: param.LevelID,
          SourceID: param.SourceID,
          CreatedOn: param.CreatedOn,
          EntityID: param.EntityID,
        };
        that.downloadLoading = true;
        API.dynamicSaleAndDynamicConsumeExcel(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "动销动耗.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function () {
            that.downloadLoading = false;
          });
      } else {
        that.$message.error({
          message: "请选择查询时间",
          duration: 2000,
        });
      }
    },
    // 合计
    getsaleTreatDetailListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalSaleQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.TotalSaleQuantity}
              </span>
            );
            break;
          case "TotalSaleAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.TotalSaleAmount)}
              </span>
            );
            break;
          case "TotalSalePrice":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.TotalSalePrice)}
              </span>
            );
            break;
          case "CurrentSaleQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.CurrentSaleQuantity}
              </span>
            );
            break;
          case "CurrentSaleAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.CurrentSaleAmount)}
              </span>
            );
            break;
          case "CurrentConsumeProjectCount":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.CurrentConsumeProjectCount}
              </span>
            );
            break;
          case "CurrentConsumeProjectQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {
                  this.result.totalForm.CurrentConsumeProjectQuantity
                }
              </span>
            );
            break;
          case "CurrentConsumeProjectAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentConsumeProjectAmount
                )}
              </span>
            );
            break;
          // eslint-disable-next-line no-duplicate-case
          case "CurrentConsumeProjectAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentConsumeProjectAmount
                )}
              </span>
            );
            break;
          case "UnSaleDay":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.UnSaleDay}
              </span>
            );
            break;
          case "UnConsumeDay":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.UnConsumeDay}
              </span>
            );
            break;
          case "ToShopQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.ToShopQuantity}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleSaleTreatDetailPageChange() {
      this.getDynamicSaleAndDynamicConsume();
    },
  },
};
</script>

<style lang="scss" >
.ReportCustomerArrearsDetail {
}
</style>